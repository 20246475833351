let contactForm: HTMLFormElement | null;
let contactFormReplacement: HTMLFormElement | null;
let interestCheckboxes: NodeListOf<HTMLInputElement>;
let kairosInput: HTMLInputElement;
let homepageInput: HTMLInputElement;
let secondhomepageInput: HTMLInputElement;
let nameInput: HTMLInputElement;
let emailInput: HTMLInputElement;
let phoneInput: HTMLInputElement;
let gdprCheckbox: HTMLInputElement;
let submitButton: HTMLButtonElement;
let nameRequired: boolean;
let emailRequired: boolean;
let phoneRequired: boolean;
let errorMessageInterests: HTMLDivElement;
let errorMessageName: HTMLDivElement;
let errorMessageEmail: HTMLDivElement;
let errorMessagePhone: HTMLDivElement;
let errorMessageGdpr: HTMLDivElement;
let errorMessageGeneral: HTMLDivElement;

document.addEventListener("DOMContentLoaded", () => {
	contactForm = document.querySelector("form.contact-form");
	contactFormReplacement = document.querySelector(".contact-form-replacement");
	if (contactForm && typeof window.fetch === "function") {
		contactForm.setAttribute("novalidate", "true");
		interestCheckboxes = contactForm.querySelectorAll(".checkbox-interest");
		kairosInput = contactForm.querySelector("#kairos")!;
		homepageInput = contactForm.querySelector("#homepage")!;
		secondhomepageInput = contactForm.querySelector("#secondhomepage")!;
		nameInput = contactForm.querySelector("#data-name")!;
		emailInput = contactForm.querySelector("#data-email")!;
		phoneInput = contactForm.querySelector("#data-phone")!;
		gdprCheckbox = contactForm.querySelector("#checkbox-gdpr")!;
		submitButton = contactForm.querySelector('input[type="submit"]')!;
		nameRequired = nameInput.hasAttribute("required");
		emailRequired = emailInput.hasAttribute("required");
		phoneRequired = phoneInput.hasAttribute("required");
		errorMessageInterests = contactForm.querySelector(
			"#error-message-interests"
		)!;
		errorMessageName = contactForm.querySelector("#error-message-name")!;
		errorMessageEmail = contactForm.querySelector("#error-message-email")!;
		errorMessagePhone = contactForm.querySelector("#error-message-phone")!;
		errorMessageGdpr = contactForm.querySelector("#error-message-gdpr")!;
		errorMessageGeneral = contactForm.querySelector(
			"#error-message-general"
		)!;

		contactForm.addEventListener("submit", async (evt) => {
			evt.preventDefault();
			submitButton.blur();
			if (inputsAreValid()) {
				const response = await submitForm();
				const decodedResponse: { errors: Array<string> } =
					await response.json();
				if (!decodedResponse.errors.includes("interests")) {
					errorMessageInterests.classList.add("hidden");
				} else {
					errorMessageInterests.classList.remove("hidden");
				}

				if (!decodedResponse.errors.includes("name")) {
					errorMessageName.classList.add("hidden");
				} else {
					errorMessageName.classList.remove("hidden");
				}

				if (!decodedResponse.errors.includes("email")) {
					errorMessageEmail.classList.add("hidden");
				} else {
					errorMessageEmail.classList.remove("hidden");
				}

				if (!decodedResponse.errors.includes("phone")) {
					errorMessagePhone.classList.add("hidden");
				} else {
					errorMessagePhone.classList.remove("hidden");
				}

				if (!decodedResponse.errors.includes("gdpr")) {
					errorMessageGdpr.classList.add("hidden");
				} else {
					errorMessageGdpr.classList.remove("hidden");
				}

				if (!decodedResponse.errors.includes("general")) {
					errorMessageGeneral.classList.add("hidden");
				} else {
					errorMessageGeneral.classList.remove("hidden");
				}

				if (decodedResponse.errors.length == 0) {
					contactForm!.reset();
					contactForm!.classList.add("hidden");
					contactFormReplacement!.classList.remove("hidden");
				}
			}
		});
	}
});

function inputsAreValid() {
	let inputsAreValid = true;
	let interestSelected = false;
	for (const interestCheckbox of interestCheckboxes) {
		if (interestCheckbox.checked) {
			interestSelected = true;
			break;
		}
	}
	if (interestSelected) {
		errorMessageInterests.classList.add("hidden");
	} else {
		errorMessageInterests.classList.remove("hidden");
		inputsAreValid = false;
	}

	if (nameInput.validity.valid) {
		errorMessageName.classList.add("hidden");
	} else {
		errorMessageName.classList.remove("hidden");
		inputsAreValid = false;
	}

	if (emailInput.validity.valid) {
		errorMessageEmail.classList.add("hidden");
	} else {
		errorMessageEmail.classList.remove("hidden");
		inputsAreValid = false;
	}

	if (phoneInput.validity.valid) {
		errorMessagePhone.classList.add("hidden");
	} else {
		errorMessagePhone.classList.remove("hidden");
		inputsAreValid = false;
	}

	if (gdprCheckbox.checked) {
		errorMessageGdpr.classList.add("hidden");
	} else {
		errorMessageGdpr.classList.remove("hidden");
		inputsAreValid = false;
	}

	return inputsAreValid;
}

function submitForm() {
	let requestBody = {
		fetch: true,
		kairos: kairosInput.value,
		homepage: homepageInput.value,
		secondhomepage: secondhomepageInput.value,
		"data-name": nameInput.value,
		"data-email": emailInput.value,
		"data-phone": phoneInput.value,
		"checkbox-gdpr": gdprCheckbox.checked,
	};

	for (const interestCheckbox of interestCheckboxes) {
		if (interestCheckbox.checked) {
			requestBody[interestCheckbox.getAttribute("name")!] =
				interestCheckbox.checked;
		}
	}

	return fetch("/api/formsubmit", {
		method: "POST",
		body: JSON.stringify(requestBody),
	});
}
